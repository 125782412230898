const config = {
  // Changes see https://dev.azure.com/appsolut365/web%20portals/_wiki/wikis/web-portals.wiki/923/Config-Starter
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://docs.appsolut365.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://res.cloudinary.com/appsolut365/image/upload/v1641428921/appsolut365/logo/appsolut/logo_appsolut_base_hell_150_tmtjei.png',
    logoLink: '/',
    title: 'Docs',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [
      { text: 'home', link: '/' },
      { text: 'appsolut', link: 'https://appsolut.at/' },
    ],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    title:
      "<a href='https://app.appsolut365.com/'>appsolut </a><div class='greenCircle'></div><a href='/'>Documentation</a>",
    forcedNavOrder: [
      '/',
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
      '/en',
      '/de',
    ],
    collapsedNav: [], // NOT USED
    links: [{ text: 'appsolut', link: 'https://appsolut.at' }],
    frontline: false,
    ignoreIndex: false,
  },
  siteMetadata: {
    title: 'Documentation of appsolut.365 and Extensions | appsolut',
    description:
      'Documentation of the appsolut.365 product suite and Extensions from appsolut for Microsoft Business Central',
    ogImage: null,
    docsLocation: '',
    favicon:
      'https://res.cloudinary.com/appsolut365/image/upload/v1641492307/appsolut365/favicons/favicon3_pnwiwl.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'appsolut Documentation',
      short_name: 'appsolut Docs',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
