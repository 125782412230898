import config from '../../config/config';
import { getFilteredAndSortedEdges } from './nodeHelper';

export const sortTreeByPosition = (treeObject) => {
  if (treeObject && treeObject.items && treeObject.items.length > 0) {
    treeObject.items.sort((a, b) => {
      if (a.path === '/') return -1;
      else if (b.path === '/') return 1;
      else if (a.isindex && b.isindex) return a.title - b.title;
      else if (a.isindex) return 1;
      else if (b.isindex) return -1;
      else return a.position - b.position;
    });
    treeObject.items.forEach((item) => {
      if (item.items && item.items.length > 0) {
        sortTreeItemsByPosition(item.items);
      }
    });
  }
};

export const sortTreeItemsByPosition = (treeItems) => {
  if (treeItems && treeItems.length > 0) {
    treeItems.sort((a, b) => {
      if (a.path === '/') return -1;
      else if (b.path === '/') return 1;
      else if (a.isindex && b.isindex) return a.title - b.title;
      else if (a.isindex) return 1;
      else if (b.isindex) return -1;
      else return a.position - b.position;
    });
    treeItems.forEach((item) => {
      if (item.items && item.items.length > 0) {
        sortTreeItemsByPosition(item.items);
      }
    });
  }
};

export const getNavigationFromEdges = (edges, location) => {
  // get filtered edges
  const filteredAndSortedEdges = getFilteredAndSortedEdges(edges, location);

  // remove index node if configured
  const originalData = config.sidebar.ignoreIndex
    ? filteredAndSortedEdges.filter(({ node: { menupath } }) => menupath !== '/')
    : filteredAndSortedEdges;

  // create tree parent nodes
  const tree = originalData.reduce(
    (
      accu,
      {
        node: {
          fields: { slug, short, position },
          menupath,
          isindex,
        },
      }
    ) => {
      const parts = menupath.split('/');

      let { items: prevItems } = accu;

      const slicedParts =
        config.gatsby && config.gatsby.trailingSlash ? parts.slice(1, -2) : parts.slice(1, -1);

      // create tree segment of actual path/slug (without lowest element)
      for (const part of slicedParts) {
        // traverse the path top down
        let tmp = prevItems && prevItems.find(({ label }) => label == part);

        if (tmp) {
          if (!tmp.items) {
            tmp.items = [];
          }
        } else {
          tmp = { label: part, items: [] };
          prevItems.push(tmp);
        }
        // move reference to sub items
        prevItems = tmp.items;
      }

      // actual page has the lowest path element
      const slicedLength =
        config.gatsby && config.gatsby.trailingSlash ? parts.length - 2 : parts.length - 1;
      const existingItem = prevItems.find(({ label }) => label === parts[slicedLength]);

      if (existingItem) {
        // add additional data from actual page
        existingItem.url = slug;
        existingItem.path = menupath;
        existingItem.title = short;
        existingItem.position = position;
        existingItem.isindex = isindex;
      } else {
        // create new leaf element for actual page (prevItems is at lowest level of path)
        prevItems.push({
          label: parts[slicedLength],
          url: slug,
          path: menupath,
          title: short,
          position,
          isindex,
          items: [],
        });
      }
      return accu;
    },
    { items: [] }
  );

  // create ordered navigation
  sortTreeByPosition(tree);

  return tree;
};

// DEPRECATED
export const getDefaultCollapsedFromEdges = (edges, location) => {
  const treeData = getNavigationFromEdges(edges, location);
  const defaultCollapsed = {};

  treeData.items.forEach((item) => {
    if (config.sidebar.collapsedNav && config.sidebar.collapsedNav.includes(item.url)) {
      defaultCollapsed[item.url] = true;
    } else {
      defaultCollapsed[item.url] = false;
    }
  });
  return defaultCollapsed;
};
