import config from '../../config/config';

export const getActualPathFromLocation = (location) => {
  const actualPath = location ? location.pathname : '';
  return actualPath;
};

export const getPathFromNode = (node) => {
  let path = '';

  if (node) {
    path = node.fields.slug === '/' ? '' : node.fields.slug;

    // add trailing slash
    if (config.gatsby && config.gatsby.trailingSlash) {
      path = `${slug}/`;
    }
    path = path.replace('//', '/');

    // remove trailing slash
    if (config.gatsby && !config.gatsby.trailingSlash) {
      if (path !== '/') {
        path = path.endsWith('/') ? path.slice(0, -1) : path;
      }
    }

    // set empty to index
    if (!path) path = '/';
  }
  return path;
};

export const createPathFromArray = (values) => {
  let pathString = '/';

  if (values && values.length > 0) {
    const basePath = values.join('/');
    pathString = `${pathString}${basePath}`;

    // add trailing slash
    if (config.gatsby && config.gatsby.trailingSlash) {
      pathString = `${pathString}/`;
    }
    pathString = pathString.replace('//', '/');

    // remove trailing slash
    if (config.gatsby && !config.gatsby.trailingSlash) {
      if (pathString !== '/') {
        pathString = pathString.endsWith('/') ? pathString.slice(0, -1) : pathString;
      }
    }

    // set empty to index
    if (!pathString) pathString = '/';
  }
  return pathString;
};
